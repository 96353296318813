import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Company, CompanyStatus } from 'src/models/company/company';
import { CompanyService } from 'src/models/company/company.service';
import { CompanyJBPackage } from 'src/models/companyJBPackage/companyJBPackage';
import { SFServiceStatus } from 'src/models/sfService/sfService';

interface CompanyIssue {
  cta: string;
  title: string;
  description: string;
  ctaUrl: string;
}

@Component({
  selector: 'app-company-card',
  templateUrl: './company-card.component.html',
  styleUrls: ['./company-card.component.scss'],
})
export class CompanyCardComponent implements OnInit {
  company: Company;
  currentUserCanEditCompany: boolean;
  terminateSubs$: Subject<any> = new Subject();

  ctaText: string;
  ctaUrl: string;
  statusDescription: string;
  borderColor: string;
  companyLogoUrl: string;
  companyName: string;
  completePercentage: number;
  companyEditUrl: string;
  packageName: string;
  packageBadgeColor: string;
  packageTooltip: string;
  isCompanyPackageFree: boolean;

  constructor(
    private companyService: CompanyService,
    private router: Router,
    private cdr: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.companyService
      .currentUserCompanyCached()
      .pipe(takeUntil(this.terminateSubs$))
      .subscribe((company) => {
        this.company = company;
        const companyPackage = this.company?.jbPackage?.packageDetails;
        const isPackageExpired = this.company?.jbPackage?.status == SFServiceStatus.SUSPENDED;
        const isPackageTrialEnded = companyPackage?.free && isPackageExpired;

        this.packageName = isPackageTrialEnded ? 'Trial ended' : companyPackage?.name;
        this.packageBadgeColor = this.getPackageBadgeColor(company.jbPackage);
        this.setPackageTooltip(company.jbPackage, isPackageTrialEnded);
        this.isCompanyPackageFree = companyPackage?.free;
        this.setCompanyAttributes();
        this.cdr.detectChanges();
      });
  }

  getPackageBadgeColor(companyPackage: CompanyJBPackage) {
    switch (companyPackage?.status) {
      case SFServiceStatus.CANCELED:
        return 'yellow';
      case SFServiceStatus.SUSPENDED:
        return 'red';
      default:
        return 'white';
    }
  }

  setPackageTooltip(companyPackage: CompanyJBPackage, isPackageTrialEnded: boolean) {
    if (isPackageTrialEnded) {
      this.packageTooltip = 'Your free trial has ended.';
    } else if (companyPackage?.status === SFServiceStatus.SUSPENDED) {
      this.packageTooltip = 'Your plan has expired.';
    } else if (companyPackage?.status === SFServiceStatus.CANCELED) {
      this.packageTooltip =
        'You have canceled your plan. you will return to free plan on ' +
        companyPackage.nextResetDate;
    } else {
      this.packageTooltip = `You're on the ${this.packageName} plan.`;
    }
  }

  setCompanyAttributes() {
    this.companyName = this.company ? this.company.name : 'Company name';
    this.companyLogoUrl = this.company ? this.company.logoUrl : 'assets/icons/logo-text.png';
    this.completePercentage = this.company?.completePercentage;
    this.companyEditUrl = '/client/company/edit/' + this.company?.id;
    const status = this.company ? this.company.status : null;
    switch (status) {
      case CompanyStatus.INCOMPLETE:
        this.statusDescription = 'Your company record is not complete yet.';
        this.ctaText = 'Complete company';
        this.ctaUrl = this.companyEditUrl;
        break;
      case null:
        this.statusDescription = 'Your company is not created yet.';
        this.ctaText = 'Create company';
        this.ctaUrl = 'client/company/';
        break;
      case CompanyStatus.EMAIL_MISSING:
        this.statusDescription = "You need to verify your company by adding your company's email";
        this.ctaText = 'Verify company';
        this.ctaUrl = `/client/company/profile/${this.company.id}/verify`;
        break;
      case CompanyStatus.IN_REVIEW:
        this.statusDescription =
          'Your company record or new changes are in review, it will be updated soon.';
        this.ctaText = 'Edit';
        this.ctaUrl = this.companyEditUrl;
        break;
      case CompanyStatus.UNVERIFIED:
        this.statusDescription = 'Your company is unverified';
        this.ctaText = 'Update company';
        this.ctaUrl = this.companyEditUrl;
        break;
      case CompanyStatus.ACTIVE:
        this.statusDescription = 'Add verification badge to attract best talents.';
        this.ctaText = null;
        break;
      case CompanyStatus.SF_VERIFIED:
        this.ctaText = null;
        break;
    }
  }

  ctaClick(url: string) {
    this.router.navigateByUrl(url);
  }

  goToPricingPage() {
    this.router.navigateByUrl('/client/pricing');
  }

  goToCompanyPage() {
    this.router.navigateByUrl('/client/company/');
  }

  openKnowWhyOverlay() {}

  ngOnDestroy(): void {
    this.terminateSubs$.next(null);
    this.terminateSubs$.complete();
  }
}
